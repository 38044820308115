import React, {useState} from "react";
import axios from "axios";

const ContactForm = ()  => {
  const [submitSuccess, setSubmitSuccess] = useState(null)
  const [formData, setFormData] = useState({
    fullname: "",
    mail: "",
    message: "",
  })

  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    setFormData({
      ...formData,
      [name]: value,
    })
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    axios.post(`${process.env.FORMCARRY_URL}`,
      encodeForm(formData),
      {headers: {Accept: 'application/json'}}
    ).then(() => {
      clear()
      setSubmitSuccess(true)
    })
    .catch(error => {
      setSubmitSuccess(false)
    })
  };

  const clear = () => {
    setSubmitSuccess(null)
    setFormData({
      fullname: "",
      mail: "",
      message: "",
    })
  }

  const encodeForm = (data) => {
    return Object.keys(data).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key])).join('&')
  }

  return (
    <form onSubmit={handleSubmit} className={`contact-form${submitSuccess ? ' is-success' : ''}`}>
      {submitSuccess && (
        <div className="notification is-success">
          <button className="delete" onClick={() => {setSubmitSuccess(false)}}>&nbsp;</button>
          Votre message a bien &eacute;t&eacute; envoy&eacute;.
        </div>
      )}
      <div className="field">
        <label className="label is-sr-only" htmlFor="fullname">Votre nom</label>
        <div className="control">
          <input className="input" name="fullname" id="fullname" placeholder="Votre nom" value={formData.fullname} onChange={handleInputChange} required />
        </div>
      </div>
      <div className="field">
        <label className="label is-sr-only" htmlFor="mail">Votre mail</label>
        <div className="control">
          <input className="input" type="email" id="mail" name="mail" placeholder="Votre mail" value={formData.mail} onChange={handleInputChange} required />
        </div>
      </div>
      <div className="field">
        <label className="label is-sr-only" htmlFor="message">Message</label>
        <div className="control">
          <textarea className="textarea" name="message" placeholder="Message" onChange={handleInputChange} required>
            {formData.message}
          </textarea>
        </div>
      </div>
      <div className="field">
        <div className="control">
          <button type="submit" className="button is-rounded">Envoyer</button>
        </div>
      </div>
    </form>
  )
}

export default ContactForm
