import React from 'react'
import Layout from "../components/layout"
import SEO from "../components/seo"
import {Breadcrumb} from "gatsby-plugin-breadcrumb";
import ContactForm from "../components/contact-form";
import {Coordinates} from "../components/coordinates";

const Contact = ({ pageContext }) => {
  const {
    breadcrumb: { crumbs },
  } = pageContext

  return (
    <Layout>
      <SEO title="Contact" />
      <div className="container content-page content-page-contact">
        <section className="section page-header">
          <Breadcrumb
            crumbs={crumbs}
            crumbSeparator=" > "
            crumbLabel="Contactez-nous"
          />

          <h1 className="title is-1">Contactez-nous</h1>
        </section>

        <section className="section page-content">
          <div className="columns">
            <div className="column is-8">
              <div className="has-background-primary has-wave-background">
                <ContactForm/>
              </div>
            </div>
            <div className="column is-4">
              <div className="has-background-black">
                <Coordinates/>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  )
}

export default Contact
